/* eslint-disable @next/next/no-img-element */

import { Icon, IconType } from 'components/Icon';
import styles from './RoundImageWithIcon.module.css';

interface RoundImageWithIconProperties {
  alt?: string;
  icon: IconType;
  small?: boolean;
  src?: string | null;
}

export const RoundImageWithIcon = ({ alt, icon, src, small }: RoundImageWithIconProperties): JSX.Element => {
  const classNames = [styles.roundImageWithIcon, small ? styles.roundImageSmall : styles.roundImage]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classNames}>
      <div className={styles.roundImageWithIconImage}>{src && <img alt={alt} src={src} />}</div>
      <div className={styles.roundImageWithIconIcon}>
        <Icon type={icon} />
      </div>
    </div>
  );
};
