'use client';

import { captureException } from 'utils/captureException';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useUser } from 'hooks/UserContext';
import Head from 'next/head';
import styles from './Page.module.css';
import { Loader } from 'components/Loader';

const defaults = {
  description:
    'Op indebuurt ben je altijd op de hoogte van wat er speelt in jouw stad. In het klantenportaal plaats je lokale aanbiedingen en vacatures.',
  image: 'https://mijnbedrijf.indebuurt.nl/og-image.png',
  title: 'indebuurt klantenportaal',
};

interface Properties {
  children?: ReactNode;
  description?: string;
  hideIfAuth?: boolean;
  image?: string;
  loading?: boolean;
  needsAdmin?: boolean;
  needsAuth?: boolean;
  title?: string;
}

export const Page = ({
  children,
  description,
  hideIfAuth,
  image,
  loading,
  needsAdmin,
  needsAuth,
  title,
}: Properties): JSX.Element => {
  const { user, isLoadingUser, isAdmin } = useUser();
  const router = useRouter();

  const [isDoneCheckinRedirects, setIsDoneCheckinRedirects] = useState(false);

  useEffect(() => {
    const redirectOnAuthStatus = async () => {
      try {
        if (isLoadingUser) {
          return;
        }

        if (needsAuth && !user) {
          await router.push(`/uitgelogd`);
        } else if (needsAdmin && !isAdmin) {
          await router.push(`/`);
        } else if (hideIfAuth && user) {
          await router.push(`/`);
        } else {
          setIsDoneCheckinRedirects(true);
        }
      } catch (error) {
        captureException(error);
      }
    };

    redirectOnAuthStatus();
  }, [hideIfAuth, isAdmin, isLoadingUser, needsAdmin, needsAuth, router, user]);

  const titleToUse = useMemo(() => (title ? `${title} | indebuurt` : defaults.title), [title]);

  return (
    <div className={styles.container}>
      <Head>
        <title>{titleToUse}</title>
        <meta name="title" content={titleToUse} />
        <meta property="og:title" content={titleToUse} />
        <meta name="description" content={description || defaults.description} />
        <meta property="og:description" content={description || defaults.description} />
        <meta property="og:image" content={image || defaults.image} />
        <meta property="og:type" content="website" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="@PersgroepAdOps" />
        <meta property="twitter:title" content={titleToUse} />
        <meta property="twitter:description" content={description || defaults.description} />
        <meta property="twitter:image" content={image || defaults.image} />
      </Head>
      <Header />
      <main className={styles.main}>{loading || isLoadingUser || !isDoneCheckinRedirects ? <Loader /> : children}</main>
      <Footer />
    </div>
  );
};
