import {
  faArrowRight,
  faBars,
  faBuilding,
  faBullhorn,
  faCalendar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faMapMarkerAlt,
  faPlus,
  faQuestionCircle,
  faRedo,
  faSave,
  faSpinner,
  faTag,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export type IconType = keyof typeof iconTypes;
type FaIconPropertiesWithoutIcon = Omit<FontAwesomeIconProps, 'icon'>;

const iconTypes = {
  add: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faPlus} />,
  address: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faMapMarkerAlt} />,
  arrow: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faArrowRight} />,
  arrowLeft: (properties: FaIconPropertiesWithoutIcon) => (
    <FontAwesomeIcon {...properties} icon={faArrowRight} rotation={180} />
  ),
  building: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faBuilding} />,
  category: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faTag} />,
  check: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faCheck} />,
  chevron: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faChevronRight} />,
  chevronLeft: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faChevronLeft} />,
  chevronDown: (properties: FaIconPropertiesWithoutIcon) => (
    <FontAwesomeIcon {...properties} icon={faChevronRight} rotation={90} />
  ),
  close: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faTimes} />,
  calendar: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faCalendar} />,
  edit: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faEdit} />,
  externalLink: (properties: FaIconPropertiesWithoutIcon) => (
    <FontAwesomeIcon {...properties} icon={faExternalLinkAlt} />
  ),
  eye_slash: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faEyeSlash} />,
  eye: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faEye} />,
  info_circle: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faInfoCircle} />,
  megaphone: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faBullhorn} />,
  menu: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faBars} />,
  questionRound: (properties: FaIconPropertiesWithoutIcon) => (
    <FontAwesomeIcon {...properties} icon={faQuestionCircle} />
  ),
  repeat: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faRedo} />,
  save: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faSave} />,
  spinner: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faSpinner} />,
  spinningSpinner: (properties: FaIconPropertiesWithoutIcon) => (
    <FontAwesomeIcon {...properties} icon={faSpinner} spin />
  ),
  trash_alt: (properties: FaIconPropertiesWithoutIcon) => <FontAwesomeIcon {...properties} icon={faTrashAlt} />,
};

interface Properties extends FaIconPropertiesWithoutIcon {
  type: IconType;
}

export const Icon = ({ type, ...rest }: Properties): JSX.Element => {
  const IconElement = iconTypes[type];

  if (!IconElement) {
    throw new Error(`Could not find icon for type "${type}"`);
  }

  return <IconElement {...rest} />;
};
