import { Icon, IconType } from 'components/Icon';
import Link from 'next/link';
import styles from './Button.module.css';
import type { AnchorHTMLAttributes, ReactNode } from 'react';
export interface ButtonProperties {
  children?: ReactNode;
  danger?: boolean;
  disabled?: boolean;
  href?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  loading?: boolean;
  onClick?: () => void;
  secondary?: boolean;
  small?: boolean;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  type?: 'button' | 'submit' | 'reset';
}

// NOTE: when updating the button, also update the FakeButton below :)
export const Button = ({
  children,
  danger,
  disabled,
  href,
  iconLeft,
  iconRight,
  loading,
  onClick,
  secondary,
  small,
  target = '_self',
  type = 'button',
}: ButtonProperties): JSX.Element => {
  const className = [
    styles.button,
    danger ? styles.danger : undefined,
    disabled || loading ? styles.disabled : undefined,
    secondary ? styles.secondary : undefined,
    small ? styles.small : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  const isTargetBlank = target === 'blank';
  const iconRightToUse = isTargetBlank ? 'externalLink' : iconRight;

  return href ? (
    <Link href={href} legacyBehavior>
      <a className={className} target={target} rel={isTargetBlank ? 'noopener noreferrer' : undefined}>
        <ButtonInner iconLeft={iconLeft} iconRight={iconRightToUse} loading={loading}>
          {children}
        </ButtonInner>
      </a>
    </Link>
  ) : (
    <button className={className} disabled={disabled || loading} onClick={onClick} type={type}>
      <ButtonInner iconLeft={iconLeft} iconRight={iconRightToUse} loading={loading}>
        {children}
      </ButtonInner>
    </button>
  );
};

export const FakeButton = ({ children, disabled, loading, secondary, iconLeft, iconRight }: ButtonProperties) => {
  const className = [
    styles.button,
    secondary ? styles.secondary : undefined,
    disabled || loading ? styles.disabled : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={className}>
      <ButtonInner iconLeft={iconLeft} iconRight={iconRight} loading={loading}>
        {children}
      </ButtonInner>
    </div>
  );
};

interface ButtonInnerProperties {
  children?: ReactNode;
  iconLeft?: IconType;
  iconRight?: IconType;
  loading?: boolean;
}

const ButtonInner = ({ loading, iconLeft, children, iconRight }: ButtonInnerProperties) => {
  const classNames = [styles.inner, loading ? styles.hiddenContent : undefined].filter(Boolean).join(' ');
  const iconComponentLeft = iconLeft && getIconComponent(iconLeft);
  const iconComponentRight = iconRight && getIconComponent(iconRight);

  return (
    <span className={styles.innerContainer}>
      <span className={classNames}>
        {iconComponentLeft}
        {!!children && <span>{children}</span>}
        {iconComponentRight}
      </span>
      {loading && (
        <span className={styles.loader}>
          <Icon type="spinningSpinner" />
        </span>
      )}
    </span>
  );
};

function getIconComponent(icon: IconType) {
  return icon ? <Icon type={icon} /> : undefined;
}
