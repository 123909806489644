import { Button } from 'components/Button';
import { Company_company_list } from 'generated/api';
import { RoundImageWithIcon } from 'components/RoundImageWithIcon';
import { useActiveCompany } from 'hooks/ActiveCompanyProvider';
import { useCompanies } from 'utils/api';
import { useInAdminEnvironment } from 'hooks/useInAdminEnvironment';
import { useMemo } from 'react';
import { useUser } from 'hooks/UserContext';
import Link from 'next/link';
import styles from './HeaderDropdown.module.css';

const navigationLinks: { label: string; href: string }[] = [];

interface Properties {
  logout: () => Promise<void>;
}

// todo: Add onhide and accessibilty https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
export const HeaderDropdown = ({ logout }: Properties): JSX.Element => {
  const { isAdmin } = useUser();
  const inAdminEnvironment = useInAdminEnvironment();

  const navigationLinksWithAdminLink = useMemo(
    () =>
      isAdmin
        ? [
            {
              label: inAdminEnvironment ? 'Naar bedrijfsomgeving' : 'Naar admin omgeving',
              href: inAdminEnvironment ? '/' : '/admin',
            },
            ...navigationLinks,
          ]
        : navigationLinks,
    [inAdminEnvironment, isAdmin]
  );

  return (
    <div className={styles.container}>
      {!inAdminEnvironment && (
        <>
          <Companies />
          <hr className={styles.divider} />
        </>
      )}

      <div>
        {navigationLinksWithAdminLink.map(({ label, href }) => (
          <Link href={href} key={href} legacyBehavior>
            <a title={label} className={styles.link}>
              {label}
            </a>
          </Link>
        ))}
        <button title="logout" onClick={logout} className={styles.link}>
          Uitloggen
        </button>
      </div>
    </div>
  );
};

const Companies = () => {
  const { activeCompany, setActiveCompanyUuid } = useActiveCompany();
  const { user } = useUser();

  const hasUser = useMemo(() => !!user, [user]);

  const { data: companies } = useCompanies({ enabled: hasUser });
  const companiesWithoutActiveCompany = useMemo(
    () => (companies || [])?.filter(({ uuid }) => uuid !== activeCompany?.uuid),
    [activeCompany?.uuid, companies]
  );

  return (
    <div>
      {activeCompany && (
        <div>
          <div className={styles.title}>Je bekijkt momenteel</div>
          <CompanyThumbnail isActive company={activeCompany} />
        </div>
      )}

      {companiesWithoutActiveCompany.length > 0 && (
        <>
          <hr className={styles.divider} />
          <div className={styles.title}>Andere organisaties</div>
          <div className={styles.companiesList}>
            {companiesWithoutActiveCompany?.map((company) => (
              <CompanyThumbnail
                company={company}
                isActive={false}
                key={company?.uuid}
                setActiveCompanyUuid={setActiveCompanyUuid}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const CompanyThumbnail = ({
  company,
  isActive,
  setActiveCompanyUuid,
}: {
  company?: Company_company_list;
  isActive: boolean;
  setActiveCompanyUuid?: (uuid?: string) => void;
}) => (
  <div className={[styles.companyThumbnail, isActive ? styles.isActive : undefined].filter(Boolean).join(' ')}>
    <div className={styles.companyThumbnailContent}>
      <div className={styles.companyThumbnailImageContainer}>
        <RoundImageWithIcon
          alt={`${company?.name} afbeelding`}
          icon="check"
          small={!isActive}
          src={company?.primaryImage}
        />
      </div>
      <div>
        <div className={styles.companyThumbnailName}>{company?.name}</div>
        <div className={styles.companyThumbnailLocation}>{company?.location}</div>
        {isActive && (
          <div className={styles.companyThumbnailEditButtonContainer}>
            <Button href={`/bedrijven/${company?.uuid}/bewerken`} small>
              Naar bedrijfspagina
            </Button>
          </div>
        )}
      </div>
    </div>
    {!isActive && setActiveCompanyUuid && (
      <div className={styles.companyThumbnailButtonContainer}>
        <Button onClick={() => setActiveCompanyUuid(company?.uuid)} secondary small iconLeft="arrow" />
      </div>
    )}
  </div>
);
