import type { ReactNode } from 'react';
import styles from './MaxWidth.module.css';

interface Properties {
  children: ReactNode;
  size?: 'small' | 'medium';
}

export const MaxWidth = ({ children, size }: Properties): JSX.Element => (
  <div
    className={[
      styles.container,
      size === 'small' ? styles.small : undefined,
      size === 'medium' ? styles.medium : undefined,
    ]
      .filter(Boolean)
      .join(' ')}
  >
    {children}
  </div>
);
