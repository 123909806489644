import { MaxWidth } from 'components/MaxWidth';
import { useUser } from 'hooks/UserContext';
import Link from 'next/link';
import styles from './Footer.module.css';
import { RichText } from './RichText';

const links = [
  { label: 'Gebruiksvoorwaarden', href: 'https://indebuurt.nl/gebruiksvoorwaarden/' },
  { label: 'Privacy', href: 'https://privacy.dpgmedia.nl/nl/document/privacy-policy' },
];

export const Footer = (): JSX.Element => {
  const { user } = useUser();
  return (
    <footer className={styles.container}>
      <MaxWidth>
        <div className={styles.content}>
          <div>&copy; {new Date().getFullYear()} — indebuurt.nl</div>
          {user ? (
            <RichText>
              <a href="mailto:support@indebuurt.nl">support@indebuurt.nl</a>
            </RichText>
          ) : undefined}
          <div className={styles.linksContainer}>
            {links.map(({ label, href }) => (
              <Link key={href} href={href} legacyBehavior>
                <a className={styles.link} target="_blank" rel="noopener noreferrer">
                  {label}
                </a>
              </Link>
            ))}
          </div>
        </div>
      </MaxWidth>
    </footer>
  );
};
