import { HeaderDropdown } from 'components/HeaderDropdown';
import { Icon } from 'components/Icon';
import { RoundImageWithIcon } from 'components/RoundImageWithIcon';
import { useActiveCompany } from 'hooks/ActiveCompanyProvider';
import { useInAdminEnvironment } from 'hooks/useInAdminEnvironment';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useUser } from 'hooks/UserContext';
import Image from 'next/image';
import Link from 'next/link';
import logo from './logo.webp';
import styles from './Header.module.css';
import { useAdminStatusSummary } from 'utils/api';

const AdminNavigationLinks = () => {
  const { asPath } = useRouter();
  const { data: adminStatus } = useAdminStatusSummary();

  const links = [
    { label: 'Gebruikers', href: '/admin/gebruikers', count: adminStatus?.registrations },
    { label: 'Bedrijven', href: '/admin/bedrijven', count: adminStatus?.companies },
    { label: 'Aanbiedingen', href: '/admin/aanbiedingen', count: adminStatus?.offers },
    { label: 'Agendapunten', href: '/admin/agendapunten', count: adminStatus?.calendarEvents },
  ].map(({ href, label, count }) => (
    <Link key={href} href={href} legacyBehavior>
      <a
        className={[
          styles.navigationLink,
          (href === '/' ? href === asPath : asPath.includes(href)) ? styles.active : undefined,
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {count !== undefined && count > 0 && <span className={styles.badge}></span>}
        {label}
      </a>
    </Link>
  ));
  return <>{links}</>;
};

const UserNavigationLinks = () => {
  const { asPath } = useRouter();
  const links = [
    { label: 'Dashboard', href: '/' },
    // { label: 'Aanbiedingen', href: '/aanbiedingen' },
    // { label: 'Agendapunten', href: '/agendapunten' },
  ].map(({ href, label }) => (
    <Link key={href} href={href} legacyBehavior>
      <a
        className={[
          styles.navigationLink,
          (href === '/' ? href === asPath : asPath.includes(href)) ? styles.active : undefined,
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {label}
      </a>
    </Link>
  ));
  return <>{links}</>;
};

export const Header = (): JSX.Element => {
  const { activeCompany } = useActiveCompany();
  const { user, logout } = useUser();
  const inAdminEnvironment = useInAdminEnvironment();

  const navigationLinks = inAdminEnvironment ? <AdminNavigationLinks /> : <UserNavigationLinks />;

  const [accountDropdownOpen, setAccountDropdownOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <header className={styles.container}>
      <Link className={styles.logo} href={inAdminEnvironment ? '/admin' : user ? '/' : '/login'}>
        <Image alt="indebuurt logo" src={logo} fill />
      </Link>

      {user && (
        <>
          <button className={styles.menuButton} onClick={() => setMenuOpen(!menuOpen)}>
            <Icon type={menuOpen ? 'close' : 'menu'} />
          </button>

          <div className={[styles.mobileMenu, menuOpen ? styles.isOpen : undefined].filter(Boolean).join(' ')}>
            {/* This spacer is needed on desktop */}
            <div />

            <nav className={styles.navigation}>{navigationLinks}</nav>
            <AccountToggle
              image={activeCompany?.primaryImage}
              name={inAdminEnvironment ? user.name : activeCompany?.name}
              onToggle={() => setAccountDropdownOpen(!accountDropdownOpen)}
              subtitle={inAdminEnvironment ? 'Admin' : activeCompany?.location}
            />
            {accountDropdownOpen && (
              <div className={styles.dropDownContainer}>
                <HeaderDropdown logout={logout} />
              </div>
            )}
          </div>
        </>
      )}
    </header>
  );
};

interface AccountToggleProperties {
  image?: string | null;
  name?: string;
  onToggle: () => void;
  subtitle?: string;
}

const AccountToggle = ({ image, subtitle = '', name = '', onToggle }: AccountToggleProperties): JSX.Element => (
  <button className={styles.accountToggle} onClick={onToggle}>
    <div className={styles.accountToggleImageContainer}>
      <RoundImageWithIcon alt={name} icon="check" src={image} />
    </div>
    <div className={styles.accountToggleNameContainer}>
      <div className={styles.accountToggleName}>{name}</div>
      <div>{subtitle}</div>
    </div>

    <div className={styles.accountToggleChevron}>
      <Icon type="chevronDown" />
    </div>
  </button>
);
